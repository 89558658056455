import axiosInstance from "./axiosClient";

export async function getContent() {
  try {
    const response = await axiosInstance.get("get-content");
    const { data, success } = response.data;

    if (!success) {
      throw new Error();
    }

    return { content: data, isErrorOccured: false };
  } catch (error) {
    return { content: {}, isErrorOccured: true };
  }
}

/** PROMPTS */
export async function getPrompts() {
  try {
    const response = await axiosInstance.get("get-prompts");
    const { data, success } = response.data;

    if (!success) {
      throw new Error();
    }

    return { prompts: data, isErrorOccured: false };
  } catch (error) {
    return { prompts: [], isErrorOccured: true };
  }
}

export async function getMidjourneyPrompts() {
  try {
    const response = await axiosInstance.get("get-midjourney-prompts");
    const { data, success } = response.data;

    if (!success) {
      throw new Error();
    }

    return { prompts: data, isErrorOccured: false };
  } catch (error) {
    return { prompts: [], isErrorOccured: true };
  }
}

/** FAVOURITES ENDPOINTS */
export async function getUserFavoritesPrompts() {
  try {
    const response = await axiosInstance.get(`get-favorites`);

    const { data, success } = response.data;

    if (!success) {
      throw new Error();
    }

    return { promptsId: data, isErrorOccured: false };
  } catch (error) {
    return { promptsId: [], isErrorOccured: true };
  }
}

export async function addPromptToFavorites(promptId, updateFavoritePrompts) {
  try {
    const response = await axiosInstance.post("add-to-favorites", {
      promptId: promptId,
    });
    const { data, success } = response.data;

    if (!success) {
      throw new Error(
        `Wystąpił błąd: ${response.status} - ${response.statusText}`
      );
    }

    const favoritesIds = data.favorites.map((el) => el.id);
    await updateFavoritePrompts(favoritesIds);
    return data;
  } catch (error) {
    console.error("Wystąpił błąd:", error.message);
    throw error;
  }
}

export async function removePromptFromFavorites(
  promptId,
  updateFavoritePrompts
) {
  try {
    const response = await axiosInstance.delete(
      `remove-from-favorites/${promptId}`
    );
    const { data, success } = response.data;

    if (!success) {
      throw new Error(
        `Wystąpił błąd: ${response.status} - ${response.statusText}`
      );
    }

    await updateFavoritePrompts(data);
    return data;
  } catch (error) {
    console.error("Wystąpił błąd:", error.message);
    throw error;
  }
}

/** USER PROMPTS */
export async function addPromptToUser(content, category) {
  try {
    const requestBody = {
      content,
      category: category || "Bez kategorii",
    };

    const response = await axiosInstance.post("add-user-prompt", requestBody);

    const { data, success } = response.data;

    if (!success) {
      throw new Error(
        `Wystąpił błąd: ${response.status} - ${response.statusText}`
      );
    }

    return data;
  } catch (error) {
    console.error("Wystąpił błąd:", error.message);
    throw error;
  }
}

export async function deleteUserPrompt(promptId) {
  try {
    const response = await axiosInstance.delete(
      `delete-user-prompt/${promptId}`
    );
    const { data, success } = response.data;

    if (!success) {
      throw new Error(
        `Wystąpił błąd: ${response.status} - ${response.statusText}`
      );
    }

    return data;
  } catch (error) {
    console.error("Wystąpił błąd:", error.message);
    throw error;
  }
}

export async function editUserPrompt(promptId, content, category) {
  try {
    const requestBody = {
      content,
      category: category,
    };

    const response = await axiosInstance.put(
      `edit-user-prompt/${promptId}`,
      requestBody
    );
    const { data, success } = response.data;

    if (!success) {
      throw new Error(
        `Wystąpił błąd: ${response.status} - ${response.statusText}`
      );
    }

    return data;
  } catch (error) {
    console.error("Wystąpił błąd:", error.message);
    throw error;
  }
}

export async function getUserPrompts() {
  try {
    const response = await axiosInstance.get(`get-user-prompts`);
    const { data, success } = response.data;

    if (!success) {
      throw new Error();
    }

    return { prompts: data, isErrorOccured: false };
  } catch (error) {
    return { prompts: [], isErrorOccured: false };
  }
}

export async function generateMidjourneyPrompt(text) {
  try {
    const response = await axiosInstance.post("generate-midjourney-prompt", {
      userInput: text,
    });

    const { data, success } = response.data;

    if (!success) {
      throw new Error(
        `Wystąpił błąd: ${response.status} - ${response.statusText}`
      );
    }

    return data;
  } catch (error) {
    console.error("Wystąpił błąd podczas generowania promptów:", error.message);
    throw error;
  }
}

export async function generatePrompt(text, level) {
  try {
    const response = await axiosInstance.post("generate-prompt", {
      text,
      level,
    });

    const { message, success } = response.data;

    if (!success) {
      throw new Error(
        `Wystąpił błąd: ${response.status} - ${response.statusText}`
      );
    }

    const jsonData = JSON.parse(message);

    const generateSection = (sections) => {
      return sections
        .map((section) => `- ${section.nazwa}: ${section.wyjaśnienie}`)
        .join("\n");
    };

    const basicSections = [
      jsonData.rolaAI,
      jsonData.zadanieIOgraniczenia,
      jsonData.oczekiwaniaCoDoRezultatu,
    ];

    const advancedSections = [
      jsonData.kontekstSytuacyjny,
      jsonData.rolaAI,
      jsonData.charakterystykaRozmówcy,
      jsonData.zadanieIOgraniczenia,
      jsonData.oczekiwaniaCoDoRezultatu,
      jsonData.strukturaRezultatu,
    ];

    const professionalSections = [
      `Oto słownik firmowy:\n${generateSection(
        jsonData.uzgodnienieRzeczywistości
      )}`,
      jsonData.kontekstSytuacyjny,
      jsonData.typIOpisProblemuDoRozwiązania,
      jsonData.rolaAI,
      jsonData.charakterystykaRozmówcy,
      jsonData.zadanieIOgraniczenia,
      jsonData.oczekiwaniaCoDoRezultatu,
      jsonData.strukturaRezultatu,
    ];

    let generatedPrompt = "";

    switch (level) {
      case "basic":
        generatedPrompt = basicSections.join("\n\n");
        break;
      case "advanced":
        generatedPrompt = advancedSections.join("\n\n");
        break;
      case "professional":
        generatedPrompt = professionalSections.join("\n\n");
        break;
      default:
        throw new Error(`Nieznany poziom: ${level}`);
    }

    return { message: generatedPrompt };
  } catch (error) {
    console.error("Wystąpił błąd podczas generowania promptów:", error.message);
    throw error;
  }
}
