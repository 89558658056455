import {
  getTokenFromCookies,
  getRefreshTokenFromCookies,
  removeTokens,
  saveTokenCookie,
  getCampusTokenFromCookies,
} from "./cookies/tokenCookies";
import axios from "axios";
import appRouter from "./appRouter";

export const BASE_URL = "https://plugin.campusai.pl/api/";

// Create axios instance.
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  // withCredentials: true,
});

axiosInstance.interceptors.request.use(function (config) {
  const token = getTokenFromCookies();
  const campusToken = getCampusTokenFromCookies();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers["campus-access-token"] = token ? `Bearer ${campusToken}` : "";

  config.headers["Content-Type"] = "application/json";

  return config;
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Response interceptor to handle 401 errors and token refreshing
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    const isLoginPage = originalRequest.url?.includes("/login");

    if (
      error.response?.status === 401 &&
      !originalRequest?._retry &&
      !isLoginPage
    ) {
      if (isRefreshing) {
        // If already refreshing, queue the original request
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            if (originalRequest.headers) {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axiosInstance(originalRequest);
            } else {
              return Promise.reject(error);
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = getRefreshTokenFromCookies();
      const campusAccessToken = getCampusTokenFromCookies();

      if (!refreshToken || !campusAccessToken) {
        removeTokens();
        appRouter.navigate("/login");
        return Promise.reject(error);
      }

      return new Promise(function (resolve, reject) {
        axios
          .post(`${BASE_URL}/auth/refresh-token`, {
            refreshToken: refreshToken,
            campusAccessToken: campusAccessToken,
          })
          .then(({ data }) => {
            const { token } = data;

            saveTokenCookie(data.token);
            // saveRefreshTokenCookie(data.refreshToken);
            axiosInstance.defaults.headers["Authorization"] = "Bearer " + token;

            if (originalRequest.headers) {
              originalRequest.headers["Authorization"] = "Bearer " + token;
            }
            processQueue(null, token);
            resolve(axiosInstance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            removeTokens();
            appRouter.navigate("/login");
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
