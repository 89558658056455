import { getUserLoginData } from "../../services/auth";
import { toast } from "react-toastify";

export const handleLoginFormSubmit = async (
  e,
  password,
  navigate,
  email,
  updateUser
) => {
  e.preventDefault();

  const userData = await getUserLoginData({
    email,
    password,
  });

  if (userData) {
    const { membershipActive } = userData;

    if (!membershipActive) {
      toast.warn(
        "Twoja subskrypcja wygasła. Będziesz korzystał/a z ograniczonych możliwości platformy."
      );
    } else {
      toast.success("Zalogowano pomyślnie!");
    }

    updateUser(userData);
    navigate("/");
  }
};
