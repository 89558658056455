import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import styles from "./Login.module.scss";
import { handleLoginFormSubmit } from "./utils";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { updateUser } = useAuth();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img src="/bg-promptbook.jpg" alt="" className={styles.introImage} />
        <form
          onSubmit={(e) =>
            handleLoginFormSubmit(e, password, navigate, email, updateUser)
          }
          className={styles.loginForm}
        >
          <div className={styles.logo}>
            <img src="/logo-header.svg" alt="Logo" />
          </div>
          <h2 className={styles.header}>Zaloguj się</h2>
          <div className={styles.inputs}>
            <input
              type="text"
              id="email-input"
              placeholder="Twój email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              id="password-input"
              placeholder="Twoje hasło"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className={styles.submitButton}>
            Zaloguj się
          </button>
          <div className={styles.orDivider}>
            <span>LUB</span>
          </div>
          <div className={styles.additionalButtonsWrapper}>
            <a
              href="https://app.campusai.pl/join"
              className={styles.additionalButtons}
            >
              Załóż konto
            </a>
            <a
              href="https://app.campusai.pl/recovery"
              className={styles.additionalButtons}
            >
              Nie pamiętasz hasła?
            </a>
          </div>
          <a href="https://www.campusai.pl" className={styles.returnButton}>
            Powrót do CampusAI
          </a>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
