import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppLoading from "../../components/Loading/AppLoading";
import { useAuth } from "../../context/AuthContext";
import { getUser } from "../../services/auth";
import {
  getCampusTokenFromCookies,
  getTokenFromCookies,
  removeTokens,
} from "../../services/cookies/tokenCookies";

export function RequireAuth({ children }) {
  const navigate = useNavigate();
  const { updateUser, user } = useAuth();

  useEffect(() => {
    const fetchUser = async () => {
      const token = getTokenFromCookies();
      const campusAccessToken = getCampusTokenFromCookies();
      const tokensExist = token && campusAccessToken;

      if (!user && tokensExist) {
        try {
          const userData = await getUser();
          updateUser(userData);
        } catch (error) {
          console.log("Error fetching user:", error);
        }
      } else if (!tokensExist) {
        removeTokens();
        navigate("/login");
      }
    };

    fetchUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) return <AppLoading />;

  // if (!loggedIn) {
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }

  return children;
}
