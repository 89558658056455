import axiosInstance from "./axiosClient";
import {
  removeTokens,
  saveCampusTokenCookie,
  saveRefreshTokenCookie,
  saveTokenCookie,
} from "./cookies/tokenCookies";
import { toast } from "react-toastify";
import axios from "axios";
import appRouter from "./appRouter";

const URL_LOGIN = "auth/login";
const URL_USER = "auth/get-user-data";

export async function getUser() {
  try {
    const response = await axiosInstance.post(URL_USER);
    const { user, success } = response.data;

    if (!success) {
      throw new Error();
    }

    return user;
  } catch (error) {
    const is404Error =
      axios.isAxiosError(error) && error.response?.status === 401;
    removeTokens();
    appRouter.navigate("/login");

    if (is404Error === false) {
      toast.error("Wystąpił błąd podczas pobierania informacji o użytkowniku");
    }
  }
}

export async function getUserLoginData(userData) {
  try {
    const response = await axiosInstance.post(URL_LOGIN, {
      email: userData.email,
      password: userData.password,
    });

    const { token, refreshToken, campusAccessToken, user, success } =
      response?.data;

    if (!success) {
      throw new Error();
    }

    saveTokenCookie(token);
    saveRefreshTokenCookie(refreshToken);
    saveCampusTokenCookie(campusAccessToken);

    return user;
  } catch (error) {
    toast.error("Błąd logowania. Spróbuj ponownie.");
    return null;
  }
}

export function logOut() {
  removeTokens();
  appRouter.navigate("/login");
}
